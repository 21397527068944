
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/Flaticon.eot");
  src: url("../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon/Flaticon.woff") format("woff"),
       url("../fonts/flaticon/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
font-size: inherit;
font-style: normal;
}
.flaticon{
	font-family: Flaticon;
	font-size: 20px;
	font-style: normal;
}

.flaticon-car-steering-wheel:before { content: "\f100"; }
.flaticon-happy:before { content: "\f101"; }
.flaticon-baths-marker-point:before { content: "\f102"; }
.flaticon-driver-license:before { content: "\f103"; }
.flaticon-delivery-man:before { content: "\f104"; }
.flaticon-delivery-man-1:before { content: "\f105"; }