@import "./assets/css/fonts.css";
@import "./assets/css/font-awesome.min.css";
@import "./assets/css/custom_animation.css";

@import "./assets/css/flaticon.css";
@import "./assets/css/style.css";
@import "./assets/css/responsive.css";

.customeWidth {
    width: 100%;
}

.course_thumb > img {
    width: auto;
    max-height: 300px;
    max-width: -webkit-fill-available;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.logo {
    height: 50px;
    object-fit: contain;
}

.bg-center {
    background-position: center !important;
    background-size: cover !important;
}

.react-date-picker__wrapper {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.thanksBtn {
    font-size: 72px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}