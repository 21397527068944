
@media(max-width:1200px){
	.license_box .shape {
		right: -38%;
	}
}
@media(min-width:992px){
	.nav_toggle{
		display:none;
	}
	.header_right_menu {
		display: block !important;
	}
}
@media(max-width:991px){
	.home_slider .slides{
		background-position:center center;
	}
	/*=== about section css ===*/
	.ds_about_desc h3 {
		margin-top: 25px;
	}
	/*=== about section css ===*/
	/* header menu css start */
	.ds_header_section {
		padding: 14px 0;
	}
	.ds_logo {
		margin-top: 0;
	}
	.nav_toggle {
		display: block;
		z-index: 999;
		margin: 7px 0 0px;
	}
	.header_menu_section {
		width: 100%;
		float: left;
		position: relative;
		padding: 18px 0;
	}
	.header_right_menu {
		float: left;
		width: 280px;
		position: fixed;
		z-index: 99;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: #fff;
		border-right: 3px solid #f94740;
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
		transform: translateX(-100%);
		-moz-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		-o-transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
	}
	.header_right_menu.menu_open{
		transform: translateX(0);
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		-webkit-transform: translateX(0);
	}
	.header_right_menu > ul{
		padding-top: 50px;
		padding-bottom: 50px;
		text-align:left;
	}
	.header_right_menu > ul > li {
		width: 100%;
		float: left;
		padding: 0;
	}
	.header_right_menu > ul > li > a {
		padding: 10px 10px 10px 25px;
		margin: 0;
		color: #222222;
	}
	.header_right_menu > ul > li > a:after, 
	.header_right_menu > ul > li > a:before {
		display:none;
	}
	/*=== submenu css ===*/
	.header_right_menu > ul > li > ul {
		position: relative;
		float: left;
		text-align:left;
		background-color: transparent;
		border-top:none;
		top: 0;
		left: 0;
		transition: inherit;
		-moz-transition: inherit;
		-webkit-transition: inherit;
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		transform: none;
		padding-left: 15px;
		display: none;
	}
	.header_right_menu > ul > li:hover >ul {
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		transform: none;
	}
	.header_right_menu > ul > li > ul:before {
		display:none;
	}
	.header_right_menu > ul > li > ul > li {
		border-bottom:none;
	}
	.header_right_menu > ul > li > ul > li > a {
		padding: 7px 15px;
	}
	.caret_down {
		position: absolute;
		right: 25px;
		top: 9px;
		text-align: center;
		color: #f94740;
		font-size: 25px;
		line-height: 30px;
		cursor: pointer;
		-webkit-transition: all 300ms ease-in-out;
		-moz-transition: all 300ms ease-in-out;
		-o-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
	}
	.caret_down:before {
		position: absolute;
		content: "\f196";
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		left: 0;
		top: 0;
	}
	.caret_down.caret_up:before {
		content: "\f147";
	}
	/*=== submenu css ===*/
	/*==== course section css ====*/
	.course_figure {
		width:auto;
		max-width: 272px;
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	/*==== course section css ====*/
	.course_thumb img {
		display: inline-block;
		width: 100%;
	}
	/*==== blog css ====*/
	.blog_sidebar {
		margin-top: 50px;
	}
	/*==== blog css ====*/
	/*==== footer css ====*/
	.ds_footer_section .widget ul li p {
		line-height: 27px;
	}
	/*==== footer css ====*/
	/*==== license css ====*/
	.license_box .line {
		right: auto;
		left: 50%;
		top: 100%;
		margin-top: 0;
		margin-left: -1px;
		width: 2px;
		height: 100%;
		background-color: #f94740;
	}
	.license_box .shape {
		right: auto;
		top: auto;
		bottom: -36%;
		left: 50%;
		margin-top: 0;
		margin-left: -10px;
	}
	/*==== license css ====*/
}
@media(max-width:767px){
	.red_btn:after {
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-o-transform: scale(1.1);
	}
	/*=== header top css ===*/
	.header_top ul {
		width: 100%;
		text-align: center;
	}
	.header_top ul > li {
		display: inline-block;
		float: none;
		margin-right: 0;
		margin-bottom: 10px;
		width: 100%;
	}
	/*=== header top css ===*/
	/*== scrollbar css== */
	.header_right_menu {	
		overflow-y: scroll;
	}
	.header_right_menu::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		border-radius: 10px;
		background-color: #f1f1f1;
	}

	.header_right_menu::-webkit-scrollbar{
		width: 10px;
		background-color: #f1f1f1;
	}

	.header_right_menu::-webkit-scrollbar-thumb{
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		background-color: #a2b3fb;
	}
	.header_right_menu:scrollbar{
		width: 10px;
		background-color: #f1f1f1;
	}
	.header_right_menu:scrollbar-thumb{
		border-radius: 10px;
		box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		background-color: #a2b3fb;
	}
	/*== scrollbar css== */
	.instruct_figure {
		width: auto;
		max-width: 270px;
		float: none;
		margin-left: auto;
		margin-right: auto;
		display: table;
	}
	/*==== blog css ====*/
	.blog_section_wrap .blog_items {
		display: table;
		max-width: 372px;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
	#comments ul li ul {
		padding-left: 10px;
	}
	/*==== blog css ====*/
}
@media(max-width:610px){
	.cmt_title .reply_link {
		position: absolute;
		bottom: 10px;
		right: 20px;
	}
}
@media(max-width:480px){
	/*=== slider css ===*/
	.slider_caption h3 {
		font-size: 22px;
	}
	.slider_caption h2 {
		font-size: 28px;
		line-height: 34px;
	}
	/*=== slider css ===*/
	.specific_comment .right_avtar_details {
		padding-left: 0;
		width: 100%;
		float: left;
		padding-top: 15px;
	}
	.contact_details > li > div{
		display: block;
		padding-left: 0;
		padding-top: 18px;
	}
	.social_share span{
		display:block;
	}
	.social_share .social_icons {
		display: block;
		padding-left: 0;
		overflow: hidden;
		margin-bottom: 0;
		margin-top: 10px;
	}
	.ui-datepicker {
		width: 100%;
		left:0 !important;
	}
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
}