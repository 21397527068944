/*----------------------------------------------------------------------------------
 1. body start
----------------------------------------------------------------------------------*/
body {
    margin: 0;
    padding: 0;
    line-height: 1.42857143;
	font-size:14px;
	-webkit-font-smoothing: antialiased;
	font-family: 'Open Sans', sans-serif;
}
::selection {
	background: #f94740;
	color: #ffffff;
}
/*----------------------------------------------------------------------------------
 1. body end
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
 2. common css Start
----------------------------------------------------------------------------------*/
/*===== Section heading css======*/
.heading_section{
	float:left;
	width:100%;
	padding:0 15px;
	margin-bottom:50px;
	text-align:center;
}
.heading_section h1 {
    color: #343434;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 30px;
}
.heading_section span {
    display: inline-block;
    position: relative;
    font-size: 36px;
    color: #f94740;
}
.heading_section span i {
    font-size: 36px;
}
.heading_section span:before, 
.heading_section span:after {
    position: absolute;
    content: "";
    width: 58px;
    height: 2px;
    background: #343434;
    top: 50%;
    margin-top: -1px;
}
.heading_section span:before{
    left: -100%;
    margin-left: -29px;
}
.heading_section span:after {
    right: -100%;
    margin-right: -29px;
}
/*===== Section heading css======*/

.full_width{
	float:left;
	width:100%;
}
h1, h2, h3, h4, h5, h6{
	font-family: 'Roboto', sans-serif;
}
a, input, button{
	transition:all ease-in 0.25s;
	-moz-transition:all ease-in 0.25s;
	-webkit-transition:all ease-in 0.25s;
}
a:focus, input:focus, button:focus, textarea:focus {
    outline: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    text-decoration: none;
}
a:after, button:after, 
a:before, button:before{
	transition:all ease-in 0.25s;
	-moz-transition:all ease-in 0.25s;
	-webkit-transition:all ease-in 0.25s;
}
/*===== button css start =====*/
.red_btn{
	color: #fff;
	background:transparent;
	border: none;
	padding: 0px 15px;
	font-size: 15px;
	font-weight: 600;
	height:50px;
	line-height:50px;
    text-decoration: none;
	display:inline-block;
	position:relative;
	z-index:1;
	backface-visibility: hidden;
	transition:all ease-in 0.25s;
	-moz-transition:all ease-in 0.25s;
	-webkit-transition:all ease-in 0.25s;
}
.red_btn:before, 
.red_btn:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f94740;
    z-index: -1;
	transition:all ease-in 0.25s;
	-moz-transition:all ease-in 0.25s;
	-webkit-transition:all ease-in 0.25s;
}
.red_btn:after {
	background-color:transparent;
    border: 1px solid #f94740;
	transform:scale(1.2);
	-webkit-transform:scale(1.2);
	-moz-transform:scale(1.2);
	-ms-transform:scale(1.2);
	-o-transform:scale(1.2);
	opacity:0;
}
.red_btn:hover, 
.red_btn:focus{
	text-decoration:none;
	color:#f94740;
}
.red_btn:hover:before, 
.red_btn:focus:before{
	transform:scale(0.5);
	-moz-transform:scale(0.5);
	-ms-transform:scale(0.5);
	-o-transform:scale(0.5);
	-webkit-transform:scale(0.5);
	opacity:0;
}
.red_btn:hover:after, 
.red_btn:focus:after{
	transform:scale(1);
	-webkit-transform:scale(1);
	-moz-transform:scale(1);
	-ms-transform:scale(1);
	-o-transform:scale(1);
	opacity:1;
}
/*===== button css start =====*/
/*==== preloader css Start ====*/
.preloader {
    position: fixed;
    z-index: 9999;
    background: #ffffff;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
}
.preloader_inner {
    position: absolute;
    top: 50%;
    left: 50%;
	width:90px;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
}
.loading_icon{
	animation: loader 1s infinite;
	-webkit-animation: loader 1s infinite;
	-moz-animation: loader 1s infinite;
}
.preloader_inner i {
    font-size:50px;
    color:#f94740;
}
.preloader_inner p{
    line-height: 23px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
    color: #f94740;
	animation:load_fade 1s infinite;
	-webkit-animation:load_fade 1s infinite;
	-moz-animation:load_fade 1s infinite;
}
/*==== preloader css End ====*/
/*----------------------------------------------------------------------------------
 2. common css End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
 3. header top css Start
----------------------------------------------------------------------------------*/
.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 11;
}
.header_top {
    width: 100%;
    float: left;
    padding: 19px 0;
    border-bottom: 1px solid #7c807f;
}
.header_top ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.header_top .contact_desc {
    float: left;
}
.header_top .timing_shed {
    float: right;
}
.header_top ul > li {
    float: left;
    margin-right: 40px;
    color:#ffffff;
    font-size: 14px;
    font-weight: 400;
}
.header_top ul > li > i{
    margin-right: 7px;
}
.header_top ul > li:last-child {
    margin-right: 0;
}
/*----------------------------------------------------------------------------------
 3. header top css End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
 4. header menu css start
----------------------------------------------------------------------------------*/
.header_menu_section {
	width: 100%;
    float: left;
    position: relative;
}
.ds_logo{
    float: left;
    margin-top: 30px;
}
.ds_logo > a{
	display:inline-block;
}
.header_right_menu{
	float:right;
}
.header_right_menu ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.header_right_menu > ul{
	float: left;
	width: 100%;
}
.header_right_menu ul > li {
    float: left;
    position: relative;
    perspective-origin: center;
    -webkit-perspective-origin: center;
    perspective: 400px;
    -webkit-perspective: 400px;
    -moz-perspective: 400px;
    padding: 33px 10px;
}
.header_right_menu > ul > li > a {
    font-size: 17px;
    color: #ffffff;
    letter-spacing: 0.05em;
    display: block;
    text-transform: capitalize;
    text-decoration: none;
    margin: 0 5px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
	position:relative;
}
.header_right_menu > ul > li > a:after, 
.header_right_menu > ul > li > a:before {
    position: absolute;
    content: "";
    width: 100%;
	opacity:0;
    height: 1px;
    background-color: #f94740;
}
.header_right_menu > ul > li > a:before {
    left: 0;
    top: 0;
}
.header_right_menu > ul > li > a:after {
    bottom: 0;
    right: 0;
}
.header_right_menu > ul > li > a:hover:after, 
.header_right_menu > ul > li > a:hover:before {
    width: 25px;
    opacity: 1;
}
.header_right_menu ul > li > a:hover {
    color: #f94740;
}
/*==== sub-menu css End ====*/
.header_right_menu > ul > li > ul {
    position: absolute;
	text-align:center;
    background-color: #fff;
	border-top:2px solid #f94740;
    min-width: 200px;
    z-index: 99;
    top: 100%;
    left: 50%;
    transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
    -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
    -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(-50%,-20px);
    -moz-transform: translate(-50%,-20px);
    -ms-transform:translate(-50%,-20px);
    -o-transform: translate(-50%,-20px);
    transform: translate(-50%,-20px);
    padding-top: 0;
}
.header_right_menu > ul > li > ul:before {
    position: absolute;
    content: "";
    left: 50%;
    top: -11px;
	margin-left:-8px;
    border-bottom: 11px solid #f94740;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}
.header_right_menu > ul > li > ul > li {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    padding: 0;
}
.header_right_menu > ul > li > ul > li > a {
    white-space: nowrap;
    margin: 0;
    font-size: 14px;
    color: #333;
    text-decoration: none;
    padding: 11px 15px;
    display: block;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}
.header_right_menu > ul > li > ul > li > a:hover {
    padding-left: 20px;
}
.header_right_menu > ul > li:hover > ul {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%,0px);
    -moz-transform: translate(-50%,0px);
    -ms-transform: translate(-50%,0px);
    -o-transform: translate(-50%,0px);
    transform: translate(-50%,0px);
}
/*==== sub-menu css End ====*/
/*=== nav toggle css ===*/
.nav_toggle {
    position: relative;
    cursor: pointer;
    width: 35px;
    height: 30px;
	float: right;
}
.nav_toggle i {
    float: left;
    width: 100%;
    background: #f94740;
    height: 4px;
    margin-bottom: 3px;
    border-radius: 2em;
	transition:all ease-in 0.25s;
	-moz-transition:all ease-in 0.25s;
	-webkit-transition:all ease-in 0.25s;
}

.nav_toggle:hover i:nth-child(2){
	width:90%;
}
.nav_toggle:hover i:nth-child(3){
	width:80%;
}
.nav_toggle.toggle_open i:first-child {
    transform: translateY(12px) rotate(45deg);
    -webkit-transform: translateY(12px) rotate(45deg);
    -moz-transform: translateY(12px) rotate(45deg);
    -ms-transform: translateY(12px) rotate(45deg);
    -o-transform: translateY(12px) rotate(45deg);
    margin: 0;
	width:100%;
}
.nav_toggle.toggle_open i:nth-child(2) {
    transform: translateY(8px) rotate(-45deg);
    -webkit-transform: translateY(8px) rotate(-45deg);
    -moz-transform: translateY(8px) rotate(-45deg);
    -ms-transform: translateY(8px) rotate(-45deg);
    -o-transform: translateY(8px) rotate(-45deg);
    margin: 0;
	width:100%;
}
.nav_toggle.toggle_open i:nth-child(3) {
    transform: translateY(-50px) rotate(90deg);
    -webkit-transform: translateY(-50px) rotate(90deg);
    -moz-transform: translateY(-50px) rotate(90deg);
    -ms-transform: translateY(-50px) rotate(90deg);
    -o-transform: translateY(-50px) rotate(90deg);
    opacity: 0;
    visibility: hidden;
}
/*=== nav toggle css ===*/
/*----------------------------------------------------------------------------------
4. header menu css End
----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------
5. slider banner start
----------------------------------------------------------------------------------*/
.ds_slider_section{
	float:left;
	width:100%;
	position:relative;
}
.home_slider{
	float:left;
	width:100%;
}
.home_slider .slides {
    float: left;
    width: 100%;
    background-size: cover;
	background-position:center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}
.home_slider .slides:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(0, 0, 0, 0.48);
    z-index: -1;
}
.slider_caption_wrap {
    padding: 250px 0 100px;
}
.slider_form{
	background-color: rgba(0, 0, 0, 0.7);
}
.slider_form form {
    padding: 30px;
}
.slider_form .form_heading {
    padding: 24px 20px;
    text-align: center;
    background: #f94740;
    color: #fff;
}
.slider_form .form_heading h2 {
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 0;
    line-height: 28px;
}
.slider_form .form_heading p {
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}
.slider_form .form_group {
    margin-bottom: 20px;
}
.date_wrapper{
	position:relative;
}
.date_wrapper input {
    cursor: pointer;
}
.date_wrapper .date_icon {
    color: #fff;
    position: absolute;
    font-size: 14px;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #f94740;
    text-align: center;
    line-height: 50px;
    padding: 0 13px;
    pointer-events: none;
    cursor: pointer;
    transition: all ease-in-out 0.25s;
    -webkit-transition: all ease-in-out 0.25s;
    -moz-transition: all ease-in-out 0.25s;
}
.slider_form form input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-weight: 600;
}
.slider_form .join_btn {
    width: 100%;
    background: transparent;
}
/*==== calendar css start ====*/
.ui-datepicker {
    width: 300px;
    border-radius: 0;
    margin-top: 5px;
}
.ui-datepicker .ui-datepicker-header {
	border-radius: 0;
    background-color: #f94740;
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
.ui-datepicker th {
    padding: .7em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #343434;
}
.ui-datepicker-calendar tr td a {
    color: #151515;
    font-size: 15px;
    border: none !important;
    padding: 5px;
    text-align: center;
}
.ui-datepicker-calendar tr td .ui-state-default.ui-state-active, 
.ui-datepicker-calendar tr td a:hover {
    background: #f94740;
	color:#ffffff;
}
.ui-datepicker-calendar .ui-state-default.ui-state-highlight {
    background: #767676;
    color: #fff;
}
/*==== calendar css end ====*/
/*===== slider caption css =====*/
.slider_caption {
    text-align: center;
    color: #fff;
    padding: 132px 0;
}
.slider_caption h3 {
    font-weight: 500;
    font-size: 26px;
}
.slider_caption h2 {
    font-size: 37px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0 20px;
}
.slider_caption p {
    font-size: 16px;
    line-height: 24px;
}
.slider_caption .slide_btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding: 0 32px;
    margin-top: 25px;
}
.owl-controls .owl-nav > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: 25px;
	transition: all ease-in 0.25s;
	-moz-transition: all ease-in 0.25s;
	-webkit-transition: all ease-in 0.25s;
}
.home_slider .owl-controls .owl-nav > div{
	opacity:0;
	visibility:hidden;
}
.home_slider:hover .owl-controls .owl-nav > div{
	opacity:1;
	visibility:visible;
}
.owl-controls .owl-nav .owl-prev{
	left:10px;
}
.owl-controls .owl-nav .owl-next{
	right:10px;
}
.owl-controls .owl-nav > div > img {
  max-width: 100%;
}
.owl-item.active .slider_form{
	animation:fromleft 1s 1 both .3s ease-in-out;
	-webkit-animation:fromleft 1s 1 both .3s ease-in-out;
	-moz-animation:fromleft 1s 1 both .3s ease-in-out;
}
.owl-item.active .slider_caption h3{
	animation:fromtop 1s 1 both .5s;
	-webkit-animation:fromtop 1s 1 both .5s;
	-moz-animation:fromtop 1s 1 both .5s;
}
.owl-item.active .slider_caption h2{
	animation:fromtop 1s 1 both 1s;
	-webkit-animation:fromtop 1s 1 both 1s;
	-moz-animation:fromtop 1s 1 both 1s;
}
.owl-item.active .slider_caption p{
	animation:fromtop 1s 1 both 1.5s;
	-webkit-animation:fromtop 1s 1 both 1.5s;
	-moz-animation:fromtop 1s 1 both 1.5s;
}
.owl-item.active .slider_caption .slide_btn{
	animation:frombottom 1s 1 both 2s;
	-webkit-animation:frombottom 1s 1 both 2s;
	-moz-animation:frombottom 1s 1 both 2s;
}
/*===== slider caption css =====*/
/*----------------------------------------------------------------------------------
5. slider banner End
----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------
6. service section Start
----------------------------------------------------------------------------------*/
.service_section {
    float: left;
    width: 100%;
    padding-top: 80px;
}
.service_box {
    float: left;
    width: 100%;
    text-align: center;
    border: 1px solid #f5f5f5;
    padding: 0px 20px 40px;
    margin-bottom: 80px;
    margin-top: 20px;
	position:relative;
}
.service_box:before, 
.service_box:after {
    position: absolute;
    content: "";
    width: 30%;
    height: 100%;
	transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
}
.service_box:before{
	left: 0;
	top: 0;
	border-left: 1px solid #f94740;
	border-bottom: 1px solid #f94740;
}
.service_box:after{
	right: 0;
	top: 0;
	border-right: 1px solid #f94740;
	border-top: 1px solid #f94740;
}
.service_box:hover:before, 
.service_box:hover:after{
	width: 100%;
	opacity:1;
} 
.service_box i {
    font-size: 28px;
    color: #f94740;
    border: 1px solid;
    width: 65px;
    height: 65px;
    line-height: 65px;
    border-radius: 100%;
    margin-top: -32px;
    background-color: #fff;
    z-index: 1;
    display: inline-block;
    position: relative;
}
.service_box h4 {
    font-size: 20px;
    font-weight: 500;
    color: #343434;
    margin: 19px 0 17px;
}
.service_box p {
    color: #767676;
    line-height: 25px;
    margin-bottom: 0;
}
/*----------------------------------------------------------------------------------
 6. service section End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
7. testimonial section start
----------------------------------------------------------------------------------*/
.testimonial_section{
	float:left;
	width:100%;
	background:url(http://via.placeholder.com/1920x484) no-repeat;
	background-size:cover;
}
.black_overlay {
	float:left;
	width:100%;
    background: rgba(0, 0, 0, 0.75);
}
.testimonial_section .black_overlay{
	padding:80px 0;
}
.testimonial_slider{
	float: left;
	width: 100%;
}
.testimonial_cover {
    float: left;
    width: 100%;
    text-align: center;
}
.testimonial_cover i {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 4px solid;
    border-radius: 100%;
    color: #fff;
    font-size: 45px;
    margin-bottom: 40px;
}
.testimonial_cover p {
    color: #fff;
    font-size: 17px;
    line-height: 25px;
    font-style: italic;
}
.testimonial_cover h3 {
    font-size: 20px;
    color: #ffffff;
    margin-top: 40px;
}
.testimonial_cover h5 {
	font-size: 15px;
	color: #ffffff;
	font-weight:400;
}
/*----------------------------------------------------------------------------------
7. testimonial section End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
8. courses section Start
----------------------------------------------------------------------------------*/
.courses_section{
	float:left;
	width:100%;
	padding-top:80px;
}
.courses_section .heading_section {
    margin-bottom: 75px;
}
.course_figure {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    background-color: #252525;
}
.course_figure .c_price {
    position: absolute;
    width: 76px;
    height: 76px;
    line-height: 74px;
    border-radius: 100%;
    text-align: center;
    background-color: #f94740;
    border: 3px solid #fff;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    z-index: 01;
    right: 15px;
    top: 0;
    margin-top: -38px;
}
.course_thumb{
	position:relative;
	perspective-origin: left center;
	-webkit-perspective-origin: left center;
	perspective: 400px;
	-webkit-perspective: 400px;
}
.course_thumb img {
    display: inline-block;
}
.course_overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.67);
    top: 0;
    left: 0;
    text-align: center;
	transition:all ease-in .3s;
	-webkit-transition:all ease-in .3s;
	-moz-transition:all ease-in .3s;
	opacity:0;
	visibility:hidden;
	transform: rotateY(45deg);
    -webkit-transform: rotateY(45deg);
    -moz-transform: rotateY(45deg);
    -ms-transform: rotateY(45deg);
    -o-transform: rotateY(45deg);
}
.course_figure:hover .course_overlay{
	opacity:1;
	visibility:visible;
	transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
}
.course_overlay .overlay_inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    height: auto;
    width: auto;
}
.course_overlay .book_btn {
    height: 40px;
    line-height: 38px;
    font-size: 14px;
}
.course_desc {
    padding: 25px 0;
}
.course_desc h4 {
    font-size: 20px;
    color: #ffffff;
    margin: 0px 0 6px;
}
.course_desc p {
    margin-bottom: 0;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
/*----------------------------------------------------------------------------------
8. courses section End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
9. skills section End
----------------------------------------------------------------------------------*/
.skills_section{
	float:left;
	width:100%;
	background:url(http://via.placeholder.com/1920x300) no-repeat;
	background-size:cover;
}
.skills_section .black_overlay{
	padding:80px 0 30px;
}
.counter_figure{
	float:left;
	width:100%;
	text-align:center;
	margin-bottom:50px;
}
.counter_figure i {
    font-size: 36px;
    color: #f94740;
    border: 2px solid;
    background-color: #fff;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    display: inline-block;
    border-radius: 100%;
	transition: all ease-in 0.25s;
    -moz-transition: all ease-in 0.25s;
    -webkit-transition: all ease-in 0.25s;
}
.counter_figure h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 10px;
    margin-top: 30px;
    line-height: 40px;
}
.counter_figure p {
    margin-bottom: 0;
    color: #ffffff;
    text-transform: capitalize;
    margin-top: 0;
}
.counter_figure:hover i{
	background-color:transparent;
}
/*----------------------------------------------------------------------------------
9. skills section End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
10. instruction section End
----------------------------------------------------------------------------------*/
.instructor_team_section{
	float:left;
	width:100%;
	padding-top:80px;
	padding-bottom:30px;
}
.instruct_figure {
    float: left;
    width: 100%;
    margin-bottom: 50px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
	-moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}
.instruct_desc {
    float: left;
    width: 100%;
    text-align: center;
    border: 1px solid #f1f1f1;
}
.instruct_desc h4 {
    font-size: 20px;
    color: #343434;
    font-weight: 500;
    margin-top: 17px;
    margin-bottom: 5px;
}
.instruct_desc h5 {
    font-size: 15px;
    color: #767676;
    font-weight: normal;
    margin: 0;
}
.instruct_desc ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.instruct_desc ul.social_icons {
    padding: 10px 0;
    border-top: 1px solid #f1f1f1;
    margin-top: 25px;
}
.instruct_desc ul.social_icons li{
	display:inline-block;
}
.instruct_desc ul.social_icons li a {
    display: inline-block;
    color: #343434;
    font-size: 16px;
    padding: 5px;
}
.instruct_desc ul.social_icons li a:hover{
	color:#f94740;
}
/*----------------------------------------------------------------------------------
10. instruction section End
----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------
11. About Page css Start
----------------------------------------------------------------------------------*/
.ds_page_banner{
	background:url(http://via.placeholder.com/1920x403) no-repeat;
	background-size:cover;
}
.ds_page_banner .black_overlay {
    background: rgba(0, 0, 0, 0.50);
}
.page_content_wrap {
    float: left;
    width: 100%;
    padding: 250px 0 75px;
}
.page_heading {
    text-align: center;
}
.page_heading h1{
	color:#ffffff;
}

.breadcrumb_section{
	float:left;
	width:100%;
	text-align:center;
}
.breadcrumb_section .breadcrumb {
    margin-bottom: -18px;
    display: inline-block;
    padding: 12px 30px;
    background-color: #f94740;
    border-radius: 2em;
    position: relative;
    z-index: 1;
}
.breadcrumb_section .breadcrumb > li {
    display: inline-block;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
	color:#ffffff;
	position: relative;
}
.breadcrumb>li+li:before {
    color: #fff;
}
.breadcrumb_section .breadcrumb > li > a {
	text-decoration:none;
	color:#ffffff;
}
.breadcrumb_section .breadcrumb > li > a:hover {
	color:#343434;
}
.ds_about_section{
	padding: 80px 0;
}
.ds_about_thumb{
	float: left;
	width: 100%;
	position:relative;
}
.thumb_hover:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: block;
    content: '';
    background: rgba(255,255,255,.2);
    opacity: 0;
}
.ds_about_desc{
	float: left;
	width: 100%;
}
.ds_about_desc h3 {
    font-size: 22px;
    color: #343434;
    font-weight: 500;
    margin-bottom: 13px;
}
.ds_about_desc p {
    color: #767676;
    line-height: 24px;
}
.ds_about_desc .read_more {
    padding: 0px 38px;
    margin-top: 20px;
}
.thumb_hover{
	position:relative;
	overflow:hidden;
}
.thumb_hover:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: block;
    content: '';
    background: rgba(255,255,255,.2);
    opacity: 0;
}
.thumb_hover:hover.thumb_hover:before {
    animation: squarehover 1s 1 ease-in;
    -webkit-animation: squarehover 1s 1 ease-in;
    -moz-animation: squarehover 1s 1 ease-in;
}
/*----------------------------------------------------------------------------------
11. About Page css End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
12. contact Page css Start
----------------------------------------------------------------------------------*/
.contact_section{
	padding:80px 0;
}
.contact_details {
    float: left;
    width: 100%;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.contact_details > li {
    float: left;
    width: 100%;
    margin-bottom: 35px;
}
.contact_details > li > span, 
.contact_details > li > div {
    display: table-cell;
    vertical-align: top;
}
.contact_details > li > .icons i {
    background: #f94740;
    color: #fff;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    text-align: center;
    font-size: 28px;
}
.contact_details > li > div {
    padding-left: 30px;
}
.contact_details > li > div h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 10px;
    color: #343434;
}
.contact_details > li > div p {
    color: #767676;
    line-height: 24px;
    margin-bottom: 2px;
}
.contact_details > li > div p strong {
    font-weight: 600;
    color: #343434;
}
/*===== contact form css Start =====*/
.contact_form{
	float:left;
	width:100%;
}
.contact_form h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 25px;
    color: #343434;
}
.contact_form form input, 
.contact_form form textarea {
    width: 100%;
    background-color: #fbfbfb;
    border: none;
    height: 45px;
    padding: 0 15px 0 20px;
    margin-bottom: 20px;
    color: #767676;
}
.contact_form form textarea {
    height: 130px;
    padding-top: 15px;
	resize: none;
}
.contact_form .submit_btn {
    padding: 0 57px;
}
#err_msg p {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px;
}
/*===== contact form css End =====*/

/*===== map css End =====*/
.map_section{
	width:100%;
	float:left;
}
#contact_map{
	width:100%;
	height:450px;
}
/*===== map css End =====*/
/*----------------------------------------------------------------------------------
12. contact Page css End
----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------
13. Pricing Section css Start
----------------------------------------------------------------------------------*/
.pricing_section{
	width:100%;
	float:left;
	background:url(http://via.placeholder.com/1920x780) no-repeat;
	background-size:cover;
}
.pricing_section .black_overlay {
    padding-top: 80px;
}
.heading_section.heading_light h1 {
    color: #ffffff;
}
.heading_section.heading_light span:before, 
.heading_section.heading_light span:after{
	background-color: #ffffff;
}
.pricing_main {
    float: left;
    width: 100%;
}
.pricing_body {
    width: 100%;
    float: left;
    margin-bottom: 90px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #adadad;
	transition: all ease-in 0.25s;
    -moz-transition: all ease-in 0.25s;
    -webkit-transition: all ease-in 0.25s;
}
.price_head {
 
}
.price_head h3 {
    color: #343434;
    text-transform: capitalize;
    display: inline-block;
    font-size: 20px;
    margin: 40px 0;
    padding-bottom: 10px;
    position: relative;
}
.price_head h3:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0;
    height: 1.5px;
    width: 50px;
    margin-left: -25px;
    background-color: #f94740;
}
.plan_price {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 100%;
    background-color: #f94740;
    color: #fff;
    margin-bottom: 30px;
	word-break: break-word;
}
.plan_price h4 {
    font-size: 21px;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}
.plan_price h4 span {
    font-size: 14px;
}
.pricing_details{
	margin-bottom:30px;
}
.pricing_details li {
    line-height: 40px;
    font-size: 16px;
    color: #767676;
}
.pricing_body .price_btn {
    padding: 0 50px;
    margin-bottom: 40px;
}
.pricing_body:hover {
    transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -o-transform: translateY(-25px);
}
/*----------------------------------------------------------------------------------
13. Pricing Section css End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
14. Blog Section css Start
----------------------------------------------------------------------------------*/
.blog_section_wrap {
    padding-top: 80px;
    padding-bottom: 30px;
    float: left;
    width: 100%;
}
.blog_items {
    float: left;
    width: 100%;
    margin-bottom: 50px;
    box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.1);
}
.blog_thumb{
	position: relative;
	perspective-origin: left center;
	-webkit-perspective-origin: left center;
	perspective: 400px;
	-webkit-perspective: 400px;
    float: left;
    width: 100%;
    overflow: hidden;
}
.blog_overlay {
    position: absolute;
    top: 10px;
	right:10px;
	bottom:10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.78);
	transform: rotateY(45deg);
    -webkit-transform: rotateY(45deg);
    -moz-transform: rotateY(45deg);
    -ms-transform: rotateY(45deg);
    -o-transform: rotateY(45deg);
    visibility: hidden;
    opacity: 0;
	transition:all ease-in .3s;
	-webkit-transition:all ease-in .3s;
	-moz-transition:all ease-in .3s;
}
.blog_overlay .link_icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
	cursor:pointer;
	padding: 0;
	transition: all ease-in-out 0.3s 200ms;
    -moz-transition: all ease-in-out 0.3s 200ms;
    -webkit-transition: all ease-in-out 0.3s 200ms;
	transform:translateY(-50px);
	-webkit-transform:translateY(-50px);
	-moz-transform:translateY(-50px);
	-ms-transform:translateY(-50px);
	-o-transform:translateY(-50px);
}
.blog_overlay .link_icon a{
	text-decoration:none;
	color:inherit;
	display:block;
}
.blog_thumb:hover .blog_overlay{
	 visibility: visible;
    opacity: 1;
	transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
}
.blog_thumb:hover .link_icon {
	transform:translateY(0px);
	-webkit-transform:translateY(0px);
	-moz-transform:translateY(0px);
	-ms-transform:translateY(0px);
	-o-transform:translateY(0px);
}
.blog_desc {
    float: left;
    width: 100%;
    padding: 20px;
}
.blog_desc h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
    color: #343434;
    margin-top: 5px;
    margin-bottom: 5px;
}
.blog_desc h3 a{
	text-decoration:none;
	color:inherit;
}
.blog_desc h3 a:hover{
	color: #f94740;
}
.blog_desc .post-meta {
    margin-bottom: 15px;
}
.blog_desc .post-meta span {
    color: #767676;
    font-size: 13px;
    margin-right: 10px;
}
.blog_desc .post-meta span i {
    margin-right: 3px;
}
.blog_desc .post-meta span a {
    color: #343434;
    text-decoration: none;
}
.blog_desc .post-meta span a:hover{
	color:#f94740;
}
.blog_desc p {
    color: #676770;
    line-height: 24px;
}
.read_m_link {
    color: #f94740;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: none;
}
.read_m_link:hover, 
.read_m_link:focus {
    text-decoration: none;
    color: #343434;
} 
/*----------------------------------------------------------------------------------
14. Blog Section css End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
15. Blog Single css End
----------------------------------------------------------------------------------*/
.blog_page_wrap {
    padding-top: 80px;
    padding-bottom: 30px;
    float: left;
    width: 100%;
}
.blog_single_wrap{
	padding-top: 80px;
	padding-bottom: 30px;
	float: left;
	width: 100%;
}
/*===== social share start =====*/
.social_share {
    margin-top: 35px;
}
.social_share span {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    display: table-cell;
	vertical-align: middle;
}
.social_share .social_icons {
    display: table-cell;
    list-style: none;
    padding-bottom: 0;
    padding-left: 21px;
	vertical-align: middle;
}
.social_share .social_icons li{
	float:left;
}
.social_share .social_icons li a {
    width: 35px;
    height: 35px;
    line-height: 34px;
    background: #f94740;
    color: #fff;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    margin: 5px;
    border: 1px solid transparent;
	transition: all ease-in 0.3s;
    -moz-transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
}
.social_share .social_icons li a:hover{
	background-color:transparent;
	color:#f94740;
	border:1px solid;
}
/*===== social share End =====*/
/*=== blog comment css start ===*/
#comments {
    float: left;
    width: 100%;
	margin-top:25px;
}
#comments > h3{
	margin-bottom:20px;
}
#comments ul{
    float: left;
    width: 100%;
    margin: 0px;
	padding-left:0;
}
#comments ul.comment_list li {
    float: left;
    width: 100%;
}
#comments ul.comment_list li.comment{
	list-style:none;
}
#comments ul li ul{
	padding-left:30px;
}
.comment_list li .specific_comment {
    float: left;
    width: 100%;
    padding: 25px 15px 15px;
    background-color: #ffffff;
    margin-bottom: 20px;
    border: 1px solid #E4E4E4;
    position: relative;
    box-shadow: 2px 5px 14px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 2px 5px 14px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 5px 14px rgba(0, 0, 0, 0.12);
}
.specific_comment .blog_avtar {
    float: left;
}
.specific_comment .blog_avtar img {
	width:80px;
	height:80px;
	border-radius: 100%;
}
.specific_comment .right_avtar_details {
    padding-left:105px;
}
.right_avtar_details .cmt_title {
    margin-bottom: 6px;
}
.right_avtar_details .cmt_title .comment-author {
    display: inline-block;
}
.cmt_title .comment-author a {
    font-size: 17px;
    color: #343434;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}
.cmt_title .comment-author a:hover{
	color:#f94740;
}
.right_avtar_details .cmt_title span {
    color: #767676;
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
}
.cmt_title .reply_link {
    float: right;
    text-transform: capitalize;
    color: #f94740;
	text-decoration:none;
}
.cmt_title .reply_link:hover{
	color:#333333;
}
/*===blog comment css end ===*/

/*--- blog form css start ===*/
.comment-respond {
    float: left;
    width: 100%;
}
.comment-respond .comment-reply-title{
	margin:20px 0 20px;
}

.comment-respond .comment-form p {
    margin-bottom: 20px;
}
.comment-respond .comment-form .form-submit {
    margin-bottom: 0;
}
.comment-form p label {
    width: 100%;
    float: left;
    font-size: 15px;
    color: #767676;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
}
.comment-form p input {
    border: 1px solid #E4E4E4;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    color: #767676;
    outline: none;
    font-size: 14px;
}
.comment-form p textarea {
    border: 1px solid #E4E4E4;
    width: 100%;
    padding: 15px;
    color: #777777;
    outline: none;
    font-size: 14px;
	resize: none;
}
.comment-form p .comment_submit {
    background: #f94740;
    color: #fff;
    font-size: 17px;
    text-transform: capitalize;
    height: auto;
    border: 1px solid transparent;
    padding: 11px 15px;
    display: inline-block;
    transition: all ease-in-out 0.25s;
    -webkit-transition: all ease-in-out 0.25s;
    -moz-transition: all ease-in-out 0.25s;
    border-radius: 2em;
}
.comment-form p .comment_submit:hover, 
.comment-form p .comment_submit:focus{
	background-color:transparent;
	color:#f94740;
	border:1px solid;
}
/*=== blog form css end ===*/
/*----------------------------------------------------------------------------------
15. Blog Single css End
----------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------
16. Blog Sidebar css Start
----------------------------------------------------------------------------------*/
.blog_sidebar{
	float: left;
	width: 100%;
}
.blog_sidebar {
    float: left;
    width: 100%;
	margin-bottom:50px;
}
.blog_sidebar .widget {
    float: left;
    width: 100%;
    margin-bottom: 40px;
    padding: 30px;
    background: #ffffff;
    box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.1);
    border: 1px solid #E4E4E4;
}
.blog_sidebar .widget-title {
    color: #343434;
    margin-top: 0;
    font-size: 20px;
    position: relative;
    text-transform: capitalize;
    margin-bottom: 20px;
    padding-bottom: 11px;
}
.blog_sidebar .widget-title:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: #f94740;
}
.widget ul {
    padding: 0;
    margin: 0;
    list-style: none;
    float: left;
    width: 100%;
}
.widget ul li {
	float: left;
	width: 100%;
}
.blog_sidebar .widget ul li {
    line-height: 30px;
}
.blog_sidebar .widget ul li a {
    text-decoration: none;
    font-size: 14px;
    color: #505050;
}
.blog_sidebar .widget ul li a:hover {
    color: #f94740;
}
.widget ul li ul{
	padding-left:20px;
}
/*==== Search widget Start ====*/
.widget.widget_search .search-form{
	position:relative;
}
.widget.widget_search form label {
    width: 100%;
    margin-bottom: 0;
}
.widget.widget_search form .search-field {
    border: 1px solid #f94740;
    width: 100%;
    height: 45px;
    padding: 0px 66px 0 15px;
    color: #151515;
    outline: none;
    font-weight: normal;
    font-size: 14px;
    background: transparent;
}
.widget.widget_search form .search-submit {
    position: absolute;
    top: 0;
    right: 0;
    float: left;
	font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 14px 15px;
    background-color: #f94740;
    border-left: 1px solid transparent;
    border: none;
    color: #fff;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
}
.widget.widget_search form .search-submit:hover, 
.widget.widget_search form .search-submit:focus{
	color: #f94740;
	border-left:1px solid;
	background-color: transparent;
}
/*==== Search widget End ====*/

/*==== recent post widget start ====*/
.blog_sidebar .widget_recent_post ul li {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E4E4E4;
}
.blog_sidebar .widget_recent_post ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}
.blog_sidebar .widget_recent_post ul li img {
    float: left;
    width: 80px;
	height: 80px;
	transition: all ease-in-out 0.25s;
	-webkit-transition: all ease-in-out 0.25s;
	-moz-transition: all ease-in-out 0.25s;
	border-radius: 100%;
}
.blog_sidebar .widget_recent_post ul li img:hover{
	opacity:0.6;
}
.blog_sidebar .widget_recent_post ul li div {
    padding-left: 93px;
}
.blog_sidebar .widget_recent_post ul li div a {
    font-size: 16px;
    text-transform: capitalize;
    display: block;
    margin-bottom: 4px;
    margin-top: 10px;
    font-weight: 600;
    color: #202020;
    line-height: 22px;
}
.blog_sidebar .widget_recent_post ul li div p {
    color: #767676;
    font-size: 14px;
    line-height: 19px;
}
/*==== recent post widget End ====*/
/*==== Tag widget Start ====*/
.blog_sidebar .tagcloud{
	float: left;
    width: 100%;
}
.tagcloud a {
    position: relative;
    float: left;
    margin-right:7px;
    margin-bottom:7px;
    font-size: 13px;
    text-transform: capitalize;
    padding: 7px 13px;
    background: transparent;
    text-decoration: none;
    color: #fff;
    z-index: 1;
    border: 1px solid #f94740;
}
.tagcloud a:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f94740;
    z-index: -1;
    transition: all ease-in-out 0.25s;
    -webkit-transition: all ease-in-out 0.25s;
    -moz-transition: all ease-in-out 0.25s;
    opacity:1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
.tagcloud a:hover{
	color: #f94740;
}
.tagcloud a:hover:after{
	opacity:0;
	transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform:scale(0.5);
    -o-transform: scale(0.5);
}
/*==== Tag widget End ====*/
/*----------------------------------------------------------------------------------
16. Blog Sidebar css End
----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------
17. license section css Start
----------------------------------------------------------------------------------*/
.license_section{
	padding-top:80px;
}
.license_box {
    position: relative;
    margin-bottom: 80px;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    display: table;
    vertical-align: middle;
    border: 1px solid #343434;
    border-radius: 100%;
    transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
	perspective-origin: center center;
	-webkit-perspective-origin: center center;
	-moz-perspective-origin: center center;
	perspective: 400px;
	-webkit-perspective: 400px;
	-moz-perspective: 400px;
    z-index: 1;
}
.license_box:before, 
.license_box:after {
    position: absolute;
    content: "";
    left: 3px;
    top: 3px;
    bottom: 3px;
    right: 3px;
    border-radius: 100%;
    z-index: -1;
	transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
}
.license_box:before{
    background-color: #343434;
}
.license_box:after {
    background-color: #f94740;
	opacity:0;
    -webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	transform: rotateY(180deg);
}
.license_inner {
    text-align: center;
    padding-top: 39px;
    color: #fff;
}
.license_inner i {
    font-size: 36px;
    margin-bottom: 10px;
}
.license_inner h4 {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
}
.license_box .line {
    position: absolute;
    right: -100%;
    top: 50%;
    margin-top: -1px;
    width: 100%;
    height: 2px;
    background-color: #f94740;
}
.license_box .shape {
    position: absolute;
    right: -50%;
    top: 50%;
    line-height: 24px;
    color: #f94740;
    font-size: 23px;
    margin-top: -13px;
    background: #fff;
}
/*====== box hover css ======*/
.license_box:hover{
	border-color:#f94740;
}
.license_box:hover:before {
	opacity:0;
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	transform: rotateY(180deg);
}
.license_box:hover:after {
	opacity:1;
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	-o-transform: rotateY(0deg);
	transform: rotateY(0deg);
}
/*====== box hover css ======*/
/*----------------------------------------------------------------------------------
17. license section css End
----------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------------
20. Footer css Start
----------------------------------------------------------------------------------*/
.ds_footer_section{
	padding-top:80px;
	float:left;
	width:100%;
	background-color:#1f2935;
}
.footer_widget_cover {
    float: left;
    width: 100%;
}
.ds_footer_section .widget {
    float: left;
    width: 100%;
    margin-bottom: 50px;
}
.ds_footer_section .widget .widget-title {
    color: #ffffff;
    font-size: 21px;
    margin-bottom: 20px;
    font-weight: 500;
	text-transform: capitalize;
}
.footer_logo {
    margin-bottom: 16px;
}
.footer_logo a{
	display:inline-block;
}
.textwidget p {
    color: #767676;
    line-height: 24px;
}
.ds_footer_section .widget .read_link {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin-top: 8px;
}
.ds_footer_section .widget .read_link:hover{
	color:#f94740;
}
.ds_footer_section .widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ds_footer_section .widget ul li {
    line-height: 30px;
    color: #767676;
    position: relative;
}
.ds_footer_section .widget ul li a {
    font-size: 14px;
    color: #767676;
    text-decoration: none;
}
.ds_footer_section .widget ul li a:hover{
	color:#f94740;
}
.ds_footer_section .widget ul li p {
    line-height: 24px;
    font-size: 14px;
}
.ds_footer_section .address_widget ul li {
    line-height: 25px;
    margin-bottom: 10px;
    padding-left: 30px;
}
.ds_footer_section .address_widget ul li i {
    float: left;
    font-size: 17px;
    position: absolute;
    left: 0;
    top: 3px;
    color: #f94740;
}
.copyright_footer {
    border-top: 1px solid #34455a;
    padding: 25px 0;
}
.copyright_footer p {
    margin-bottom: 0;
    font-size: 15px;
    text-align: center;
    line-height: 24px;
    color: #767676;
}
.copyright_footer p a{
	color: #767676;
	text-decoration:none;
}
.copyright_footer p a:hover{
	color: #f94740;
}
/*----------------------------------------------------------------------------------
20. Footer css End
----------------------------------------------------------------------------------*/